import React from 'react'
import { Routes, Route } from 'react-router'
import './Comman.css'
import Home from './Home'
import Footer from './Footer'
import Silder from './Silder'
import About from './About'
import Product from './Product'
import Contact from './Contact'
import ProductSubAshienro from './ProductSubAshienro'
import Error from './Error'
import ProductSubAshimedin from './ProductSubAshimedin'
import ProductSubAshiverPlus from './ProductSubAshiverPlus'
import ProductSubDiroheart from './ProductSubDiroheart'
import ProductSubDurotic from './ProductSubDurotic'
import ProductSubExfreeSpot from './ProductSubExfreeSpot'
import ProductSubFurnil from './ProductSubFurnil'
import ProductBloodCell from './ProductBloodCell'
import Bufftone from './Bufftone'
import PStrong from './PStrong'

const Routing = () => {
    return (
        <>
            <React.Fragment>
                <main>
                    <Routes>
                        <Route exact to path="/" element={<Home />} />
                        <Route exact to path="/about" element={<About />} />
                        <Route exact to path="/product" element={<Product />} />
                        <Route exact to path="/contact" element={<Contact />} />

                        <Route exact to path="/productashienro" element={<ProductSubAshienro />} />
                        <Route exact to path="/productashimedin" element={<ProductSubAshimedin />} />
                        <Route exact to path="/productashiverplus" element={<ProductSubAshiverPlus />} />
                        <Route exact to path="/productdiroheart" element={<ProductSubDiroheart />} />
                        <Route exact to path="/productdurotic" element={<ProductSubDurotic />} />
                        <Route exact to path="/productexfreespot" element={<ProductSubExfreeSpot />} />
                        <Route exact to path="/productfurnil" element={<ProductSubFurnil />} />
                        <Route exact to path="/bloodcell" element={<ProductBloodCell />} />
                        <Route exact to path="/bufftone" element={<Bufftone />} />
                        <Route exact to path="/pstrong" element={<PStrong />} />

                        <Route exact to path="/silder" element={<Silder />} />

                        <Route exact to path="*" element={<Error />} />
                    </Routes>
                </main>

                <footer>
                    <Footer />
                </footer>

            </React.Fragment>

        </>
    )
}

export default Routing