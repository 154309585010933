import React from 'react'
import Navbar from './Navbar'

const ProductBloodCell = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductUp8.png")} alt='works-at-iclodsoft' />
                                </div>

                                <div className='ContentBox'>
                                    <p className='pt-3'>
                                        <span className='' style={{ color: "#ff0000" }} >
                                            <strong>
                                                RECOMMENDED USAGE (oral) :
                                            </strong>
                                        </span>
                                    </p>
                                    <p>
                                        Cattle & Buffaloes: 40 to 50 ml daily Cow, sheep & Goats: 10 to 15 ml daily Or as directed by veterinarian.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > BLOOD CELL </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Each 100 ml Contains:</p>
                                        <p>Chelated Iron(equivalent to 450 mg of Iron)5000 mg
                                            Chelated Copper (equivalent to 90 mg of copper)....1000 mg Chelated Cobalt(equivalent to 3 mg of cobalt)...60 mg
                                        </p>
                                        <p>Vitamin D3.........1,00,000 IU   </p>
                                        <p>Niacinamide.........300 mg</p>
                                        <p>Vitamin B12 .........120 mcg</p>
                                        <p>Pantothenic Acid.........220 mg</p>
                                        <p>Vitamin E......20 gm</p>
                                        <p>Kashish Basma......40 mg</p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Helps in Red Blood Cell Formation
                                        </p>
                                        <p>
                                            Helps to restore Hemoglobin Count
                                        </p>
                                        <p>
                                            Helps in Restoring platelet count
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Net Quantity : 20Kg </p>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductBloodCell