import React from 'react'
import Navbar from './Navbar'

const ProductSubDurotic = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductUp5.png")} alt='works-at-iclodsoft' />
                                </div>

                                <div className='ContentBox'>
                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    RECOMMENDED USAGE (oral) :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            50-100 gm per animal per day or 1.0 to 2.0% in compound feed or as recommended by nutritionist
                                        </p>
                                    </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > AI FORTE SUPPLEMENT </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Multi Minerals & Vitamins Supplement with Omega-3 & Omega-6 Fatty Accids </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Simulates Foillicular growth
                                        </p>
                                        <p>
                                            Helps in timely ovulation
                                        </p>
                                        <p>
                                            Prevent repeat breeding dua to early embryonic death
                                        </p>
                                        <p>
                                            Helps to combat stress
                                        </p>
                                        <p>
                                            Helps in efficient energy utilization
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Net Quantity : 300GM </p>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ProductSubDurotic