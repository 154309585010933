import React from 'react'
import Navbar from './Navbar'

const PStrong = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/PStrong.png")} alt='works-at-iclodsoft' />
                                </div>

                                <div className='ContentBox'>
                                    <p className='pt-3'>
                                        <span className='' style={{ color: "#ff0000" }} >
                                            <strong>
                                                RECOMMENDED USAGE (oral) :
                                            </strong>
                                        </span>
                                    </p>
                                    <p>
                                        Cattle: 200 ml once daily
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > PSTRONG (Animal Feed Supplement) </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Propylene glycol, Nicotinamide & Gluconeogenic Phytochemical, mical, Multivitamins & Iron Additives and taste enhancers with improved Palatability
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Gives instant & Sustained energy to animals
                                        </p>
                                        <p>
                                            Supports in maintaining normal glucose level
                                        </p>
                                        <p>
                                            Prevents and treats negative energy balance effectively
                                        </p>
                                        <p>
                                            Iron improves performance, mood, Hematology Limmunologial parameters
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Net Quantity : 600ml </p>
                                        <p> &nbsp; </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PStrong