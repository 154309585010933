import React from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'

const Product = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#7DCBEC" }} >
                    <div className="row  text-white d-flex justify-content-between align-items-center laila-semibold" style={{ padding: "15px" }} >
                        <div className='col-lg'>
                            <h5 className='mt-2' style={{ fontSize: "20px", fontWeight: "600", letterSpacing: "2px" }} >Products</h5>
                        </div>
                        <div className="col-lg">
                            <div className=' text-lg-end'>
                                <h5 className='mt-lg-2' style={{ fontSize: "16.5px", fontWeight: "500" }} >
                                    <NavLink className='text-decoration-none text-white' to='/'>
                                        Home
                                    </NavLink>
                                    <i class="fa-solid fa-angle-right ms-2 me-2" style={{ fontSize: "14px", color: "#fff" }} ></i>
                                    <NavLink className='text-decoration-none text-white' to='/product'>
                                        Products
                                    </NavLink>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-lg mt-3 mb-5 laila-semibold ProductPadding"  >
                    <div className='pb-lg-1 mb-1 mt-2' style={{ borderBottom: "1px solid #22B678" }}>
                        <h3 className='mb-3 mt-lg-3' style={{ color: "#22B678", fontWeight: "600", fontSize: "20px" }} >
                            COMPANION ANIMALS
                        </h3>
                    </div>
                    <div className="row align-items-center g-4 p-lg-2 mt-3"   >
                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/productashienro'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100' src={require("../img/ProductUp1.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/productashienro'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productashienro'>
                                            <h2 className='ProductText' >
                                                P CAL FORTE
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/productashimedin'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/ProductUp2.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/productashimedin'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productashimedin'>
                                            <h2 className='ProductText'  >
                                                AI FORTE (Clycine Chelated)
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/productashiverplus'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/ProductUp3.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/productashiverplus'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productashiverplus'>
                                            <h2 className='ProductText' >
                                                P LIV (Liver Tonic)
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/productdiroheart'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/ProductUp4.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/productdiroheart'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productdiroheart'>
                                            <h2 className='ProductText' >
                                                P CAL FORTE (GEL)
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/productdurotic'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/ProductUp5.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/productdurotic'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productdurotic'>
                                            <h2 className='ProductText' >
                                                AI FORTE SUPPLEMENT
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/productexfreespot'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/ProductUp6.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/productexfreespot'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productexfreespot'>
                                            <h2 className='ProductText' >
                                                ANIGAIN
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/productfurnil'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100 ' src={require("../img/ProductUp7.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/productfurnil'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productfurnil'>
                                            <h2 className='ProductText' >
                                                BLOOD GROW
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/bloodcell'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100' src={require("../img/ProductUp8.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/bloodcell'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productexfreespot'>
                                            <h2 className='ProductText' >
                                                BLOOD CELL
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/bufftone'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100' src={require("../img/ProductUp9.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/bufftone'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productexfreespot'>
                                            <h2 className='ProductText' >
                                            BUFFTONE
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/pstrong'>
                                <div className='ProductBox'>
                                    <div className='mb-3'>
                                        <img className='w-100' src={require("../img/PStrong.png")} alt='works-at-iclodsoft' />
                                    </div>
                                    <div className='mt-2'>
                                        <div className='d-flex justify-content-center ReadMore'>
                                            <NavLink className='text-decoration-none' to='/pstrong'>
                                                <button className='text-center'>
                                                    Read more
                                                </button>
                                            </NavLink>
                                        </div>
                                        <NavLink className='text-decoration-none' to='/productexfreespot'>
                                            <h2 className='ProductText' >
                                            PSTRONG 
                                            </h2>
                                        </NavLink>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default Product