import React from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'
import "./Comman.css"

const About = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#7DCBEC" }} >
                    <div className="row  text-white d-flex justify-content-between align-items-center laila-semibold" style={{ padding: "15px" }} >
                        <div className='col-lg'>
                            <h5 className='mt-2' style={{ fontSize: "20px", fontWeight: "600", letterSpacing: "2px" }} >About Us</h5>
                        </div>
                        <div className="col-lg">
                            <div className=' text-lg-end'>
                                <h5 className='mt-lg-2' style={{ fontSize: "16.5px", fontWeight: "500" }} >
                                    <NavLink className='text-decoration-none text-white' to='/'>
                                        Home
                                    </NavLink>
                                    <i class="fa-solid fa-angle-right ms-2 me-2" style={{ fontSize: "14px", color: "#fff" }} ></i>
                                    <NavLink className='text-decoration-none text-white' to='/about'>
                                        About Us
                                    </NavLink>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container p-4 '>
                    <div className='row d-flex justify-content-center align-items-center laila-semibold'>

                        <div className="col-lg-6">
                            <h4 className='mb-lg-4 pb-lg-2 WELCOME-TO-PASHUMITRA'> <strong> WELCOME TO PASHUMITRA </strong> </h4>
                            <div className='mt-3 me-lg-5' style={{ fontSize: "13px",fontWeight:"500",lineHeight:"27px" }} >
                                <p>Pashumitra is one of the largest providers of cattle feed and agricultural products in Maharashtra. We have been helping farmers with their cattle farming, poultry farming, and small scale agriculture needs for over 10 years. Our products are of the highest quality and are backed by our team of experts who are always available to help you get the most out of our products.</p>
                                <p> Pashumitra was established in 2010 with the goal of providing a wide range of cattle feed and agricultural products to farmers in Maharashtra. We provide products that are essential for cattle farming, poultry farming, and small-scale agriculture. Our products are of the highest quality and are designed to help our customers be successful in their farming endeavors.</p>
                                <p> We are proud to be one of the leading providers of cattle feed and agricultural products in Maharashtra. We are committed to helping our customers achieve their goals and improve their operations. We offer a wide range of products that are essential for successful farming. Contact us today to learn more about our products and how we can help you!</p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className='' >
                                <img className='w-100 ' src={require("../img/vgv.jpg")} alt='works-at-iclodsoft' />
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className=''>
                <div className='container mb-lg-5 PRODUCT-QUALITY'  >
                    <div className="row" style={{ backgroundImage: `url(${require("../img/UpdateAbout1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', borderRadius: "10px" }}>
                        <div className='laila-semibold Coloorr' style={{ padding: "35px", borderRadius: "10px" }} >
                            <div className='p-lg-4'>
                                <div className='mb-4'>
                                    <h2 className='mb-2' style={{ fontSize: "2.5rem", color: "#fff", fontWeight: "600", letterSpacing: "1px" }} > PRODUCT QUALITY </h2>
                                    <div className='' >
                                        <div className='BorderMoreArticles2' >
                                        </div>
                                    </div>
                                </div>
                                <p className='me-lg-5' style={{ fontSize: "18px", color: "#fff",fontWeight:"400" }} >
                                    The quality is beyond the horizon of any negotiation. Therefore World class manufacturing facility has been set up at Tarapur, Mumbai, that could meet stringent GMP guidelines of any regulatory body include World Health Organization. The company strictly follows GMP in all aspects of Production and distribution activities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mb-4 p-lg-4">
                    <div className="row g-4 p-1">
                        <div className="col-lg-6">
                            <div className='laila-semibold h-100' style={{ padding: "35px", borderRadius: "10px", backgroundColor: "#CED6D9" }} >
                                <div className='p-lg-3'>
                                    <div className='mb-4'>
                                        <h2 className='mb-2' style={{ fontSize: "2.5rem", color: "#32409A", fontWeight: "600", letterSpacing: "1px" }} > Vision </h2>
                                        <div className='' >
                                            <div className='BorderMoreArticles3' >
                                            </div>
                                        </div>

                                    </div>
                                    <p className='' style={{ fontSize: "19px", color: "#000",fontWeight:"400" }} >
                                        To be a World Class Leading Company in Pharmaceuticals Striving Continuously to make the benefits of science enhance the quality of life.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className='laila-semibold h-100' style={{ padding: "35px", borderRadius: "10px", backgroundColor: "#32409A" }} >
                                <div className='p-lg-3'>
                                    <div className='mb-4'>
                                        <h2 className='mb-2' style={{ fontSize: "2.5rem", color: "#fff", fontWeight: "600", letterSpacing: "1px" }} > Mission </h2>
                                        <div className='' >
                                            <div className='BorderMoreArticles4' >
                                            </div>
                                        </div>

                                    </div>
                                    <p className='' style={{ fontSize: "17.8px", color: "#fff",fontWeight:"400" }} >
                                        To deliver high quality products to all our customers worldwide with passionate focus on service leadership & knowledge that will provide total Customer delight & wellness of animals.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About