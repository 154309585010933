import React from 'react'
import Navbar from './Navbar'

const ProductSubFurnil = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductUp7.png")} alt='works-at-iclodsoft' />
                                </div>

                                <div className='ContentBox'>
                                    <p className='pt-3'>
                                        <span className='' style={{ color: "#ff0000" }} >
                                            <strong>
                                                RECOMMENDED USAGE (oral) :
                                            </strong>
                                        </span>
                                    </p>
                                    <p>
                                        Chicks: 30 g per 1000 birds daily
                                    </p>
                                    <p>
                                        Layers: 20 g per 100 birds daily
                                    </p>
                                    <p>
                                        Broilers: 20 g per 100 birds daily
                                    </p>
                                    <p>
                                        Breeders: 40 g-per 100 birds daily
                                    </p>

                                    <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    Dosage :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Large Animal: 50 g per animal, Small Animal: 25 g per animal</p>
                                        <p>Pets: 10 g per pet in meal</p>
                                        <p>Dogs: 20 g per day in feed</p>
                                        <p>Cats: 5 g per cats per animals</p>
                                        <p>Birds: 20 g per 1000 birds in drinking water, Goats/Sheep: 20 g per goats in water</p>
                                        <p>Camel: 25 g per camel in feed</p>

                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > BLOOD GROW </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Nutritional Value per 100 g</p>
                                        <p>Malt Extract......30 gm</p>
                                        <p>Elemental Calcium Gluconate...1800 mg(equivalent to 64.5 mg of Fe)</p>
                                        <p>Ferric Ammonium Citrate..300 mg</p>
                                        <p>Elemental Iron.....1600 mg</p>
                                        <p>Copper Sulphate.....500 mg</p>
                                        <p>Niacinamide..........230 mg</p>
                                        <p>Cobalt Chloride.......10 mg</p>
                                        <p>Biotin..........400 mcg</p>
                                        <p>Vitamin D3....19000 IU</p>
                                        <p>B12.....100 mcg</p>
                                        <p>Folic Acid......10000 mcg</p>
                                        <p>Cyanocobalamin......15mcg</p>
                                        <p>Glucose excipients......qs</p>
                                        <p>Flavoured Base.......qs(Colour and flavor added)</p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Anaemic condition
                                        </p>
                                        <p>
                                            Improves body weight and FCR
                                        </p>
                                        <p>
                                            Improves egg shell quality
                                        </p>
                                        <p>
                                            Improves vaccine titre
                                        </p>
                                        <p>
                                            Reduces pullet eggs
                                        </p>
                                        <p>
                                            Improves flock uniformity'
                                        </p>
                                        <p>
                                            Improves dressing percentage
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Net Quantity : 600ml </p>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductSubFurnil