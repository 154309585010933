import React from 'react'
import Navbar from './Navbar'


const ProductSubDiroheart = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductUp4.png")} alt='works-at-iclodsoft' />
                                </div>
                                <div className='ContentBox'>
                                    <p className='pt-3'>
                                        <span className='' style={{ color: "#ff0000" }} >
                                            <strong>
                                                FEEDING INSTRUCTION:
                                            </strong>
                                        </span>
                                    </p>
                                    <p>
                                        Cows/Buffalos. One tube/day 2-3 days followed by IV infusion 6-12 days before and after calving
                                        Presentation: Single dose Squeeze tube 300 gm
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > P CAL FORTE (GEL) </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Each 300 gm contains: </p>
                                        <p> lonic Calcium(Ca") : 45gm </p>
                                        <p> Magnesium, Potassium, Multivitamins (Vit A,D,B,E) </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Prevents milk fever . Helps is achieving of peak milk yield . Maintains high lactation pregnancy. Reduces Somatic cell court in milk .Prevents delay & or fluctuation in milk yield
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    RECOMMENDED USAGE (oral) :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            Admiratar une bibe 8-12 hours prior to calving and giw anotherside within-12 hours after calving
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Net Qty : 300 gm </p>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ProductSubDiroheart