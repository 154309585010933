import React from 'react'
import Navbar from './Navbar'

const ProductSubAshimedin = () => {
    return (
        <>

            <Navbar />

            <section>
                <div className="container-fluid">
                    <div className="container mt-4 mb-4 pt-lg-4 laila-semibold">
                        <div className="row g-2 d-flex justify-content-around ">
                            <div className="col-lg-6">
                                <div className='mb-3'>
                                    <img className='w-100 ' src={require("../img/ProductUp2.png")} alt='works-at-iclodsoft' />
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div>
                                    <div className='mb-3'>
                                        <h2 className='mb-2' style={{ fontSize: "18px", color: "#151515", fontWeight: "600" }} > AI FORTE (Clycine Chelated) </h2>
                                        <div className='' >
                                            <div className='BorderProductSub' >
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ContentBox'>
                                        <p>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    CONTAINS:
                                                </strong>
                                            </span>
                                        </p>
                                        <p> Each kg contains : Iron    15gm</p>
                                        <p> Live yeast culture 180x10CFU 80 gm ,Manganes  2gm </p>
                                        <p> By pass protein  100 mg, lodine 100mg </p>
                                        <p> By pass Fat 100gm, Sulphur 10mg </p>
                                        <p> Calcium 110gm  </p>
                                        <p> More .........  </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    BENEFITS :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                            To Improve fertility
                                        </p>
                                        <p>
                                            To Improve general health & immunity
                                        </p>
                                        <p>
                                            To Improve milk production & Fat percentage
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    RECOMMENDED USAGE (oral) :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>
                                        50-100 gm per animal per day or 1.0 to 2.0% in compound feed or as recommended by nutritionist
                                        </p>

                                        <p className='pt-3'>
                                            <span className='' style={{ color: "#ff0000" }} >
                                                <strong>
                                                    INFORMATION :
                                                </strong>
                                            </span>
                                        </p>
                                        <p>Net Quantity : 20Kg </p>
                                        <p> &nbsp; </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ProductSubAshimedin